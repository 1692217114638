/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
// @import '~ngx-sharebuttons/themes/default.scss';
@font-face {
    font-family: "helvetica";
    src: url("/assets/fonts/helvetica-webfont.eot");
    src: url("/assets/fonts/helvetica-webfont.eot?#iefix") format("embedded-opentype"),
        url("/assets/fonts/helvetica-webfont.woff") format("woff"),
        url("/assets/fonts/helvetica-webfont.ttf") format("truetype"),
        url("/assets/fonts/helvetica-webfont.svg#helveticaregular") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "helveticabold";
    src: url("/assets/fonts/helveticabold-webfont.eot");
    src: url("/assets/fonts/helveticabold-webfont.eot?#iefix") format("embedded-opentype"),
        url("/assets/fonts/helveticabold-webfont.woff") format("woff"),
        url("/assets/fonts/helveticabold-webfont.ttf") format("truetype"),
        url("/assets/fonts/helveticabold-webfont.svg#helveticabold") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "helveticalight";
    src: url("/assets/fonts/helveticalight-webfont.eot");
    src: url("/assets/fonts/helveticalight-webfont.eot?#iefix") format("eot"),
        url("/assets/fonts/helveticalight-webfont.woff") format("woff"),
        url("/assets/fonts/helveticalight-webfont") format("truetype"),
        url("/assets/fonts/helvetica-light-webfont.svg#helveticalight") format("svg");
    font-weight: normal;
    font-style: normal;
}

* {
    font-size: 100%;
    font-family: helvetica;
}